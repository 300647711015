<template>
    <div class="edu-frame">
        <edu-nav-bar title="编辑志愿者信息" :show="show" :hideBack="$route.query.hideBack==1" @showPopup="show=true" @hidePopup="show=false"></edu-nav-bar>
        <div class="content-frame">
            <div class="edu-group" style="margin: 20px auto;">
                <div class="step-tips">填写志愿者资料</div>
                <div class="step-tips-detail" style="color: black;">请填写您的基本资料（以下带<span style="color: red;">*</span>的是<span style="color: red;">必填项</span>）</div>
                <div class="photo-frame">
                    <img v-if="formData.imgUrl == null || formData.imgUrl == ''" class="photo" src="../../../statics/images/my.png">
                    <img v-else class="photo" :src="formData.imgUrl">
                    <div class="photo-tips">*要求相片为白底证件照</div>

                    <template v-if="(formData.imgUrl == null || formData.imgUrl == '' || formData.imgUrl.indexOf('base64') > 0)">
                        <template  v-if="formData.fage <= 15 || (formData.imgUrl == null || formData.imgUrl == ''  || formData.imgUrl.indexOf('base64') > 0)">
                            <van-button type="default" style="margin-top: 10px" @click="chooseImage">选择照片</van-button>
                            <input style="display: none;" id="browse" ref="browse" type="file" @change="previewFiles($event)">
                        </template>
                    </template>

                </div>
                <div class="class-form">
                    <van-field readonly v-model="formData.fullName" required label="姓名" placeholder="请输入姓名" clearable/>
                    <van-field readonly v-model="formData.idCardNo" required label="身份证号码" placeholder="请输入身份证号码" clearable/>
                    <van-field required name="radio" label="性别">
                        <template #input>
                            <van-radio-group v-model="formData.sex" direction="horizontal">
                                <van-radio name="1">男</van-radio>
                                <van-radio name="2">女</van-radio>
                            </van-radio-group>
                        </template>
                    </van-field>
                    <van-field required readonly clickable name="datetimePicker" :value="formData.birthday_GreStr" label="出生日期" placeholder="点击选择时间" @click="birthdayPicker = true"/>
                    <van-field v-model="formData.nationality" label="民族" placeholder="请输入民族" clearable/>
                    <van-field v-model="formData.nativePlace" label="籍贯" placeholder="请输入籍贯" clearable/>
                    <van-field readonly clickable v-model="formData.politicsStatusStr" label="政治面貌" placeholder="请选择政治面貌" clearable @click="showPolitics = true"/>
                    <van-field v-model="formData.duty" label="职业" placeholder="请输入职业" clearable/>
                    <van-field v-model="formData.healthStatus" label="身体状况" placeholder="请输入身体状况" clearable/>
                    <van-field v-model="formData.eduLevel" label="文化程度" placeholder="请输入文化程度" clearable/>
                    <van-field required v-model="formData.mobileTelNo" label="手机号码" placeholder="请输入手机号码" clearable/>
                    <van-field v-model="formData.homeAddress" label="家庭住址" placeholder="请输入家庭住址" clearable/>
                    <van-field v-model="formData.wechat" label="微信" placeholder="请输入微信" clearable/>
                    <van-field v-model="formData.email" label="邮箱" placeholder="请输入邮箱" clearable/>
                    <van-field v-model="formData.volunteerNo" label="i志愿账号" placeholder="请输入i志愿账号" clearable/>
                    <van-field v-model="formData.urgencyMan" label="紧急联系人" placeholder="请输入紧急联系人" clearable/>
                    <van-field v-model="formData.urgencyTelNo" label="联系人电话" placeholder="请输入联系人电话" clearable/>
                    <van-field required readonly clickable v-model="formData.volunteerRoleStr" label="志愿者身份" placeholder="请选择申请志愿者身份" clearable @click="showVolunteerRole = true"/>
                    <van-field required readonly clickable v-model="formData.volunteerTeam" label="分队名称" placeholder="请选择分队名称" clearable @click="showVolunteerTeam = true"/>

                    <van-field v-if="formData.volunteerRole == 1" required clickable v-model="formData.volunteerTeamChild" label="小队名称" placeholder="请填写小队名称" clearable/>
                    <van-field v-if="formData.volunteerRole == 2" required readonly clickable v-model="formData.volunteerTeamChild" label="小队名称" placeholder="请填写小队名称" clearable @click="showTeamChilds = true"/>

                    <van-field v-model="formData.intro" label="个人简历" placeholder="请输入个人简历" rows="2" autosize type="textarea" maxlength="100" show-word-limit clearable/>
                    <van-field v-model="formData.volunteerExperience" label="相关志愿服务经历" placeholder="请输入相关志愿服务经历" rows="2" autosize type="textarea" maxlength="300" show-word-limit clearable/>

                </div>
            </div>
        </div>

        <!-- 保存按钮 -->
        <div class="bottom-frame">
            <van-button :loading="submitting" square block type="primary" @click="submit">保存</van-button>
        </div>
        <!-- 保存按钮 -->

        <!-- 选择生日 -->
        <van-popup v-model="birthdayPicker" position="bottom">
            <van-datetime-picker v-model="formData.birthday_Gre" value type="date" title="选择年月日"  :min-date="minDate" :max-date="maxDate" @confirm="selectBirthday()" @cancel="birthdayPicker=false"/>
        </van-popup>
        <!-- 选择生日 -->
        <!--选择政治面貌-->
        <van-popup v-model="showPolitics" position="bottom">
            <van-picker show-toolbar :columns="politicsOpts" @confirm="selectPolitics" @cancel="showPolitics=false;"/>
        </van-popup>
        <!--选择政治面貌-->
        <!--选择申请志愿者身份-->
        <van-popup v-model="showVolunteerRole" position="bottom">
            <van-picker title="申请志愿者身份" show-toolbar :columns="volunteerRoleOpts" @confirm="selectVolunteerRole" @cancel="showVolunteerRole=false;"/>
        </van-popup>
        <!--选择申请志愿者身份-->

      <!--选择分队-->
      <van-popup v-model="showVolunteerTeam" position="bottom">
        <van-picker title="志愿者组织架构" show-toolbar :columns="volunteerTeams" @confirm="selectVolunteerTeam" @cancel="showVolunteerTeam=false;"/>
      </van-popup>
      <!--选择分队-->

      <van-popup v-model="showTeamChilds" position="bottom">
        <van-picker title="选择小队" show-toolbar :columns="volunteerTeamChilds" @confirm="selectVolunteerTeamChild" @cancel="showTeamChilds=false;"/>
      </van-popup>

    </div>
</template>
<script>
import {Button, Field, RadioGroup, Radio, Popup, DatetimePicker, Picker} from "vant";
import EduNavBar from "@/components/EduNavBar";
import Tools from "@/api/Tools";
import XEUtils from 'xe-utils'
import CustomerApi from "@/api/CustomerApi";
import ActivityApi from "@/api/ActivityApi";
export default {
    components: {
        EduNavBar,
        VanButton: Button,
        VanField: Field,
        VanRadioGroup: RadioGroup,
        VanRadio: Radio,
        VanDatetimePicker: DatetimePicker,
        VanPopup: Popup,
        VanPicker: Picker
    },
    data() {
        return {
            show: false,
            birthdayPicker: false, // 出生日期
            minDate: new Date(1900, 0, 1),
            maxDate: new Date(),
            showPolitics: false, // 政治面貌
            overCheckIDQty: 0, // 判断是否已经超过3次修改照片
            formData: { sex: '1', birthdayStr: new Date() },
            showVolunteerRole: false, // 在职状况
            politicsOpts: [{ text: '团员', value: '1' }, { text: '党员', value: '2' }, { text: '其他', value: '3' }],
            volunteerRoleOpts: [{ text: '小队长', value: '1' }, { text: '队员', value: '2' }],
            submitting: false,
            showVolunteerTeam: false,
            showTeamChilds: false,
            volunteerTeams: [],
            volunteerTeamChilds: [],
            allVolunteerTeamChilds: [],
            curVolunteerTeamId: null
        }
    },
    methods: {
        selectBirthday() {
            // 选择出生日期
            // this.formData.birthday_Gre = e
            this.$set(this.formData, 'birthday_GreStr', this.formData.birthday_Gre == null ? null : XEUtils.toDateString(this.formData.birthday_Gre, 'yyyy-MM-dd'))
            // this.$set(this.formData, 'birthday_Gre', XEUtils.toDateString(this.formData.birthday_Gre, 'yyyy-MM-dd HH:mm:ss'))
            this.birthdayPicker = false
        },
        selectPolitics(e) {
            // 选择政治面貌
            this.formData.politicsStatusStr = e.text
            this.formData.politicsStatus = e.value
            this.showPolitics = false
        },
        selectVolunteerRole(e) {
            // 志愿者身份
            this.formData.volunteerRoleStr = e.text
            this.formData.volunteerRole = e.value
            this.formData.volunteerTeam = null
            this.formData.volunteerTeamChild = null
            this.formData.volunteerTeamChildId = null
            this.curVolunteerTeamId = null
            this.showVolunteerRole = false
          this.getVolunteerTeamsChild()
        },
        selectVolunteerTeam(e) {
          console.log('e == ', e)

          // 选择小队
          this.formData.volunteerTeam = e[1] + ' ' + e[2]

          this.formData.volunteerTeamChild = null
          this.formData.volunteerTeamChildId = null
          for (var i = 0; i < this.allVolunteerTeamChilds.length; i++) {
            if (this.allVolunteerTeamChilds[i].teamName == e[2]) {
              this.curVolunteerTeamId = this.allVolunteerTeamChilds[i].teamId
              break
            }
          }

          this.showVolunteerTeam = false
          this.getVolunteerTeamsChild()
        },
        selectVolunteerTeamChild(e) {
          this.formData.volunteerTeamChildId = e.teamId
          this.formData.volunteerTeamChild = e.text
          this.showTeamChilds = false
        },
        getVolunteerTeamsChild() {
          ActivityApi.getVolunteerTeamChilds({ isTeam: 1, parentTeamId: this.curVolunteerTeamId }).then(response => {
            this.volunteerTeamChilds = JSON.parse(JSON.stringify(response.res).replaceAll('teamName', 'text'))
            console.log('this.volunteerTeamChilds == ', this.volunteerTeamChilds)
          })
        },
        previewFiles(){
            var file = document.getElementById("browse").files[0];
            var self = this
            Tools.uploadFileByBase64(file, (e)=>{
                self.$set(self.formData, 'imgUrl', e)
                console.log(self.formData.imgUrl)
            })
        },
        chooseImage() {
            this.$refs.browse.click()
        },
        submit() {
            if (this.submitting) {
              return
            }
            // 提交修改的资料
            this.submitting = true
            this.$set(this.formData, 'birthday_Gre', XEUtils.toDateString(this.formData.birthday_Gre, 'yyyy-MM-dd HH:mm:ss'))
            CustomerApi.completeVolunteerMsg(this.formData).then(response => {
                this.submitting = false
                // console.log(response)
                if (response.code==100) {
                    this.$toast({message: response.msg});
                    Tools.setCurCust(response.res)

                }
            }).catch(() => { this.submitting = false })
        },
      getVolunteerTeams() {
        ActivityApi.getVolunteerTeams({ isTeam: 0 }).then(response => {
          this.volunteerTeams = JSON.parse(JSON.stringify(response.res).replaceAll('childs', 'children'))
          this.setTeamDatas(this.volunteerTeams)
          this.volunteerTeams = [this.volunteerTeams]
        })
      },
      setTeamDatas(vt) {
        vt.text = vt.parent.teamName
        if (vt.children != null) {
          for (var i = 0; i < vt.children.length; i++) {
            this.setTeamDatas(vt.children[i])
          }
        }
      },
      getAllVolunteerTeams() {
        ActivityApi.getVolunteerTeamChilds({  }).then(response => {
          this.allVolunteerTeamChilds = response.res
        })
      }
    },
    created() {
        // 当前登录账户
        this.formData = Tools.getCurCust()
        if (this.formData.volunteerRole == 1) {
          this.formData.volunteerRoleStr = '小队长'
        } else if (this.formData.volunteerRole == 2) {
          this.formData.volunteerRoleStr = '队员'
        }
        if (this.formData.depTaxNo != null && this.formData.depTaxNo != '') {
          this.formData.lsrtype = '2'
        } else {
          this.formData.lsrtype = '1'
        }
        this.getVolunteerTeams()
        this.getAllVolunteerTeams()

        console.log(this.formData.birthday_Gre)
        this.formData.birthday_Gre = XEUtils.toStringDate(XEUtils.toDateString(this.formData.birthday_Gre), 'yyyy-MM-dd HH:mm:ss')
        this.$set(this.formData, 'birthday_GreStr', this.formData.birthday_Gre == null ? null : XEUtils.toDateString(this.formData.birthday_Gre, 'yyyy-MM-dd'))
        // 判断是否超过3次认证
        /*CustomerApi.overCheckIDQty(this.formData.custId).then(response => {
            this.overCheckIDQty = response.res
        })*/
    }
}
</script>
<style scoped>
    .photo-frame {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin: 15px auto;
    }
    .photo {
        width: 35%;
    }
    .photo-tips {
        color: red;
        font-size: 14px;
        margin-top: 5px;
    }
</style>
